<script setup>
import SvgLogoShieldNodeCrestDice from "@/assets/images/vector/logo-shield-node-crest-dice.svg"
import { useGlobalStore } from "@/stores/global"

const runtimeConfig = useRuntimeConfig()
const store = useGlobalStore()

const envPackageVersion = runtimeConfig.public.clientVersion
</script>

<template>
  <v-navigation-drawer
    v-model="store.appNavDrawer"
    temporary
    location="right"
    :scrim="true"
    floating
    class="bg-grey-darken-4"
  >
    <v-list>
      <v-list-item to="/" exact>
        <v-list-item-title>Crunch</v-list-item-title>
      </v-list-item>

      <v-list-item to="/manage-profiles">
        <v-list-item-title>Manage profiles</v-list-item-title>
      </v-list-item>

      <v-list-item to="/about">
        <v-list-item-title>About</v-list-item-title>
      </v-list-item>

      <v-list-item to="/support-unitcrunch">
        <v-list-item-title>Support UnitCrunch</v-list-item-title>
      </v-list-item>

      <v-list-item to="/faq">
        <v-list-item-title>FAQ</v-list-item-title>
      </v-list-item>

      <v-list-item to="/changelog">
        <v-list-item-title>Changelog</v-list-item-title>
      </v-list-item>

      <v-list-item to="/feedback">
        <v-list-item-title>Feedback</v-list-item-title>
      </v-list-item>

      <v-list-item to="/mathhammer">
        <v-list-item-title>What is MathHammer?</v-list-item-title>
      </v-list-item>

      <v-list-item to="/privacy-policy">
        <v-list-item-title>Privacy policy</v-list-item-title>
      </v-list-item>
    </v-list>

    <DevOnly>
      <v-divider />
      <v-list>
        <v-list-subheader>Dev</v-list-subheader>
        <v-list-item to="/dev/modifier-sandbox?scope=weapon">
          <v-list-item-title>Modifier sandbox</v-list-item-title>
        </v-list-item>

        <v-list-item to="/dev/migrate-preset-modifiers">
          <v-list-item-title>Migrate preset modifiers</v-list-item-title>
        </v-list-item>

        <v-list-item to="/dev/migrate-preset-profiles">
          <v-list-item-title>Migrate preset profiles</v-list-item-title>
        </v-list-item>
      </v-list>
    </DevOnly>

    <v-divider />

    <v-list>
      <v-list-subheader>Social</v-list-subheader>
      <v-list-item
        href="https://www.reddit.com/r/UnitCrunch/"
        target="_blank"
        rel="noopener"
      >
        r/UnitCrunch
      </v-list-item>
      <!--      <v-list-item-->
      <!--        href="https://warhammer.social/web/@unitcrunch"-->
      <!--        target="_blank"-->
      <!--        rel="me"-->
      <!--        >Mastodon</v-list-item-->
      <!--      >-->
    </v-list>

    <v-divider />

    <v-list disabled>
      <v-list-item>
        <v-list-item-title class="text-caption"
          >Version: {{ envPackageVersion }}
        </v-list-item-title>
      </v-list-item>
    </v-list>

    <v-divider />

    <div class="logo-wrapper">
      <!--      <SvgLogo class="logo" />-->
      <SvgLogoShieldNodeCrestDice class="logo" :font-controlled="false" />
    </div>
  </v-navigation-drawer>
</template>

<style lang="scss" scoped>
.logo-wrapper {
  display: flex;
  justify-content: center;
  padding: 32px 16px;
}

.logo {
  width: 50%;
  max-width: 100px;
}
</style>
